import React from "react";
import "./Podcast.css";

function Podcast() {
  return (
    <div className="podcast__container">
      <span>
        Your tips are amazingly helpful for building this musical universe that
        is unfolding!
      </span>
      <span>
        I have several ways that you can donate, and I can't thank you enough
        for your support!!!
      </span>
      <a target="_blank" href="https://venmo.com/u/MassonGuitar">
        VENMO
      </a>
      <a
        target="_blank"
        href="https://www.paypal.com/donate/?hosted_button_id=Q9ANMZSEQVF4W"
      >
        Paypal
      </a>
      <a target="_blank" href="https://cash.app/$MassonLemieux">
        CashApp
      </a>
    </div>
  );
}

export default Podcast;
