import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footerContainer">
      <div className="footerImageContainer">
        <img
          className="footerImage"
          src="https://utfs.io/f/296404cc-3381-437e-bca1-f9a9c66b5333-q1h0l8.webp"
          // src="https://utfs.io/f/6c5d7d83-ba6b-4def-8c7a-68359f5826e8-5nwuzj.PNG"
          alt="Masson LeMieux animation"
        />
      </div>
      <div>
        <h2>THE LEARNING GUITAR COMMUNITY</h2>
        <p>
          One of the best parts about playing music is doing it with others. We
          are building a community of beginner and intermediate guitar players
          who want to learn how to play guitar and become better players
        </p>
      </div>
      <span>©2024 MASSON LEMIEUX. ALL RIGHTS RESERVED</span>
    </div>
  );
}

export default Footer;
