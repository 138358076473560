import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="headerContainer">
      <div className="header__name">
        <Link to="/">
          <h2 className="nameTopLeft">Masson LeMieux</h2>
        </Link>
        <div id="logoLink" className="logo" alt="guitar">
          {/* <Link to="/" className="logo"></Link> */}
        </div>
        <label className="hamburgerMenu">
          <input type="checkbox" />
        </label>
        <aside className="dropDown">
          <nav>
            <a href="/missiontomars">LESSONS</a>
            <a href="/about">ABOUT</a>
            <a href="/contact">CONTACT</a>
          </nav>
        </aside>
        <div className="headerRight">
          <a href="/missiontomars">
            <span>Lessons</span>
          </a>
          <a href="/about">
            <span>About</span>
          </a>
          <a href="/contact">
            <span>Contact</span>
          </a>
        </div>
        <a target="blank" href="https://8680e5-15.myshopify.com">
          <img
            src="https://utfs.io/f/3bd65660-2c37-4624-a01c-aa60accb6761-1tag0.svg"
            className="cart"
            alt="cart button"
          />
        </a>
        <a href="/missiontomars">
          <button className="signUpHeader">SIGN UP</button>
        </a>
      </div>
    </div>
  );
}

export default Header;
